import gql from 'graphql-tag';

export const PAYMENT_CARD_SET_DEFAULT_MUTATION = gql`
  mutation PaymentCardSetDefault_Mutation(
    $paymentMethodId: String!
    $personId: ID!
  ) {
    setDefaultPaymentMethod(
      paymentMethodId: $paymentMethodId
      personId: $personId
    )
  }
`;

export const PAYMENT_CARD_SET_DEFAULT_REFETCH = gql`
  query PaymentCardSetDefault_Refetch($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      cardPaymentMethods {
        id
        expiryMonth
        expiryYear
        brand
        last4Digits
      }
      stripeCustomerID
    }
  }
`;

import { FirebaseProvider, AuthProvider } from '@parsleyhealth/front-end-utils';
import 'firebase/auth';
import logRocket from 'logrocket';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { App } from 'app/App';
import { AuthRouter } from 'app/components/organisms/AuthRouter/AuthRouter';
import GqlProvider from 'app/contexts/Apollo';
import LDWrapper from 'app/contexts/LDWrapper';
import { GlobalStyle } from 'app/styles/globalStyles';
import {
  COMMIT_SHA,
  FIREBASE_API_KEY,
  FIREBASE_PROJECT_ID,
  LOGROCKET_PROJECT_ID,
  NAME,
  VERSION,
} from 'constants/env';

declare global {
  interface Window {
    APP_NAME?: string;
    APP_VERSION?: string;
    COMMIT_SHA?: string;
  }
}

// Initialize LogRocket monitoring
if (LOGROCKET_PROJECT_ID) {
  logRocket.init(LOGROCKET_PROJECT_ID, {
    network: {
      requestSanitizer: (request) => {
        // remove Firebase login requests
        if (request['url'].toLowerCase().indexOf('verifypassword') !== -1) {
          request['body'] = '';
        }
        return request;
      },
    },
    release: COMMIT_SHA,
  });
}

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: `${FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: `${FIREBASE_PROJECT_ID}.appspot.com`,
};

ReactDOM.render(
  <FirebaseProvider config={firebaseConfig}>
    <AuthProvider>
      <GqlProvider>
        <LDWrapper>
          <GlobalStyle />
          <BrowserRouter>
            <AuthRouter>
              <App />
            </AuthRouter>
          </BrowserRouter>
        </LDWrapper>
      </GqlProvider>
    </AuthProvider>
  </FirebaseProvider>,
  document.getElementById('root')
);

window.APP_NAME = NAME;
window.APP_VERSION = VERSION;
window.COMMIT_SHA = COMMIT_SHA;

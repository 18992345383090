import gql from 'graphql-tag';

import { ASelectCouponField_Fragment_coupon } from 'app/components/deprecated/ASelectCouponField/fragment';
import { MemberPlanCard_Fragment_planPurchase } from 'app/components/organisms/MemberPlanCard/fragment';
import { MemberStatusTags_Fragment_member } from 'app/components/organisms/MemberStatusTags/fragment';

export const PLAN_PURCHASE_ENROLL_FROM_WAITLIST_QUERY = gql`
  query PlanPurchaseEnrollFromWaitlist_Query($personID: ID!) {
    coupons: listCoupons(onlyRedeemable: true) {
      edges {
        node {
          ...ASelectCouponField_Fragment_coupon
        }
      }
    }
    member: getPerson(id: $personID) {
      id
      center {
        id
        timezone
      }
      cardPaymentMethods {
        id
        expiryMonth
        expiryYear
      }
    }
    plans: listAllPlans {
      edges {
        node {
          id
          annualCostInCents
          billingPeriod
          defaultFollowOnPlan {
            id
            defaultFollowOnPlan {
              id
            }
          }
          deprecatedAt
          displayName
          monthsDuration
          numberOfBillingCycles
          paymentRateInCents
          product {
            displayName
            type
          }
        }
      }
    }
  }
  ${ASelectCouponField_Fragment_coupon}
`;

export const PLAN_PURCHASE_ENROLL_FROM_WAITLIST_MUTATION = gql`
  mutation PlanPurchaseEnrollFromWaitlist_Mutation(
    $input: PapiEnrollFromWaitlistInput!
  ) {
    enrollFromWaitlist(input: $input) {
      member: person {
        id
      }
      ...MemberPlanCard_Fragment_planPurchase
    }
  }
  ${MemberPlanCard_Fragment_planPurchase}
`;

export const PLAN_PURCHASE_ENROLL_FROM_WAITLIST_REFETCH = gql`
  query PlanPurchaseEnrollFromWaitlist_Refetch($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      allPlans {
        edges {
          node {
            ...MemberPlanCard_Fragment_planPurchase
          }
        }
      }
      ...MemberStatusTags_Fragment_member
    }
  }
  ${MemberPlanCard_Fragment_planPurchase}
  ${MemberStatusTags_Fragment_member}
`;

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const GET_PERSON_PAYMENT_CARDS = gql`
  query getPersonPaymentCards($id: ID!) {
    member: getPerson(id: $id) {
      id
      cardPaymentMethods {
        id
        expiryMonth
        expiryYear
        brand
        last4Digits
      }
    }
  }
`;

const usePersonPaymentCardsQuery = (options) =>
  useQuery<any>(GET_PERSON_PAYMENT_CARDS, options);

export default usePersonPaymentCardsQuery;

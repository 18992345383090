import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { Skeleton, Tooltip } from 'antd';
import gql from 'graphql-tag';
import { reverse } from 'lodash';
import { DateTime } from 'luxon';
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';

import { AIconLabeled } from 'app/components/atoms/AIconLabeled/AIconLabeled';
import { ASelect } from 'app/components/atoms/ASelect/ASelect';
import { CreateVisitCard } from 'app/components/organisms/CreateVisitCard/CreateVisitCard';
import { MemberVisitsList } from 'app/components/organisms/MemberVisitsList/MemberVisitsList';
import { VisitCard_Fragment } from 'app/components/organisms/VisitCard/fragment';
import { theme } from 'app/styles/theme';
import {
  PapiAppointmentGroup,
  PapiAppointmentStatus,
} from 'app/types/generated/globalTypes';
import {
  MemberPageVisits_Query,
  MemberPageVisits_QueryVariables,
} from 'app/types/generated/MemberPageVisits_Query';
import {
  MemberVisits_Query,
  MemberVisits_QueryVariables,
} from 'app/types/generated/MemberVisits_Query';

interface Props {
  personID: string;
}

export const MEMBER_QUERY = gql`
  query MemberPageVisits_Query($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      currentPurchase {
        id
        purchasePeriodId
        center {
          id
        }
        productType
        sequenceId
      }
      allPurchases {
        purchasePeriodId
        purchaseId
        startTime
        endTime
        purchaseName
      }
      center {
        id
        key
      }
      assignedCoach {
        id
        firstName
        lastName
      }
      assignedDoctor {
        id
        firstName
        lastName
      }
    }
  }
`;

export const MEMBER_VISITS_QUERY = gql`
  query MemberVisits_Query($personID: ID!, $purchasePeriodID: ID!) {
    visits: getAppointmentsByPurchasePeriod(
      personID: $personID
      purchasePeriodID: $purchasePeriodID
    ) {
      ...VisitCard_Fragment
    }
  }
  ${VisitCard_Fragment}
`;

export const MemberVisitsPage: React.FC<Props> = ({ personID }) => {
  const memberResult = useQuery<
    MemberPageVisits_Query,
    MemberPageVisits_QueryVariables
  >(MEMBER_QUERY, {
    variables: {
      personID: personID,
    },
  });

  const [
    getMemberVisits,
    { data: visitData, loading, refetch: refetchVisits },
  ] = useLazyQuery<MemberVisits_Query, MemberVisits_QueryVariables>(
    MEMBER_VISITS_QUERY
  );

  const reloadVisits = useCallback(() => {
    memberResult.refetch();
    refetchVisits();
  }, [memberResult, refetchVisits]);

  const member = memberResult.data?.member;
  const visits = visitData?.visits;
  const sequenceID = member?.currentPurchase?.sequenceId;
  const allMemberPurchases =
    member && member.allPurchases ? member.allPurchases : [];

  const [selectedPurchasePeriodID, setSelectedPurchasePeriodID] = useState<
    string | null
  >(null);
  const [isCreateVisible, setIsCreateVisible] = useState(false);

  useEffect(() => {
    if (member) {
      // Select the current plan by default if there is one
      if (member.currentPurchase && member.currentPurchase.purchasePeriodId) {
        setSelectedPurchasePeriodID(member.currentPurchase.purchasePeriodId);
        // Otherwise set it to the first plan
      } else if (allMemberPurchases.length > 0) {
        setSelectedPurchasePeriodID(allMemberPurchases[0].purchasePeriodId);
      }
    }
  }, [allMemberPurchases, member]);

  useEffect(() => {
    if (selectedPurchasePeriodID && member) {
      getMemberVisits({
        variables: {
          personID: member.id,
          purchasePeriodID: selectedPurchasePeriodID,
        },
      });
    }
  }, [selectedPurchasePeriodID, member, getMemberVisits]);

  if (!member || loading) {
    return (
      <Body>
        <Skeleton active paragraph={{ rows: 7, width: '40%' }} title={false} />
      </Body>
    );
  } else if (allMemberPurchases.length === 0 || !visits) {
    return <Body>This member is inactive and has no plans.</Body>;
  }

  const viewedPlan = member.allPurchases.find(
    (item) => item.purchasePeriodId === selectedPurchasePeriodID
  );

  const viewingCurrentPlan =
    member.currentPurchase?.purchasePeriodId === selectedPurchasePeriodID;

  const visitsOnPlan = visits.filter((v) => {
    return v.purchasePeriodID === selectedPurchasePeriodID;
  });

  const scheduled = visitsOnPlan.filter(
    (v) => v.status === PapiAppointmentStatus.SCHEDULED
  );

  const completed = visitsOnPlan.filter((v) => {
    return (
      v.status === PapiAppointmentStatus.COMPLETED ||
      v.status === PapiAppointmentStatus.NO_SHOW ||
      v.status === PapiAppointmentStatus.CANCELLED
    );
  });

  const unscheduled = visitsOnPlan.filter((v) => {
    return (
      v.status === PapiAppointmentStatus.UNSCHEDULED &&
      v.details.group !== PapiAppointmentGroup.LAB
    );
  });

  const total = scheduled.length + completed.length + unscheduled.length;
  const numUsed = completed.length;
  const planSelectOptions =
    allMemberPurchases
      .sort((pA, pB) => (pA.startTime < pB.startTime ? 1 : -1))
      .map((purchasePeriod) => {
        return {
          label:
            purchasePeriod.purchaseName +
            (purchasePeriod.purchasePeriodId ===
            member.currentPurchase?.purchasePeriodId
              ? ` (current)`
              : '') +
            (purchasePeriod?.startTime
              ? ` (${DateTime.fromISO(purchasePeriod.startTime).toFormat('D')})`
              : ''),
          value: purchasePeriod.purchasePeriodId,
        };
      }) || [];
  const hasCompleted = numUsed === total;

  const isSelectedPlanCurrent =
    member.currentPurchase?.purchasePeriodId === selectedPurchasePeriodID;

  const onPlanSelected = (val): void => {
    setSelectedPurchasePeriodID(val);
  };

  return (
    <Body>
      {viewedPlan === undefined ? (
        <h5>No plan is selected</h5>
      ) : (
        <>
          <Header>
            <h5>
              {viewedPlan.purchaseName} {viewingCurrentPlan ? `(current)` : ''}
            </h5>
            <PlanSelect>
              <PlanSelectLabel>Go to</PlanSelectLabel>
              <PlanSelectWrapper>
                <ASelect
                  onSelect={onPlanSelected}
                  selectOptions={reverse(planSelectOptions)}
                  value={selectedPurchasePeriodID || undefined}
                />
              </PlanSelectWrapper>
            </PlanSelect>
          </Header>
          <p>
            Plan starts on{' '}
            {(viewedPlan.startTime &&
              DateTime.fromISO(viewedPlan.startTime).toFormat('D')) ??
              ''}
            {viewedPlan.endTime
              ? `, finishes on ${DateTime.fromISO(viewedPlan.endTime).toFormat(
                  'D'
                )}`
              : null}
            <br />
            {numUsed} of {total} visits completed, {total - numUsed} remaining
          </p>
          <Columns>
            <div>
              <ColumnTitle>
                <span>Unscheduled remaining on plan</span>
                {isCreateVisible === false ? (
                  isSelectedPlanCurrent ? (
                    <Tooltip title="Add a new visit">
                      <AddVisitButton
                        data-testid="add-visit-button"
                        onClick={() => {
                          setIsCreateVisible(true);
                        }}
                      >
                        <AIconLabeled label="Add" type="plus" />
                      </AddVisitButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="You can only add visits to current plan">
                      <AddVisitButton disabled>
                        <AIconLabeled label="Add" type="plus" />
                      </AddVisitButton>
                    </Tooltip>
                  )
                ) : null}
              </ColumnTitle>

              {isCreateVisible && sequenceID && (
                <CreateVisitCard
                  member={member}
                  onClose={() => setIsCreateVisible(false)}
                  reloadVisits={reloadVisits}
                />
              )}

              {hasCompleted ? (
                <div>
                  The member has completed all their visits on this plan.
                </div>
              ) : unscheduled.length === 0 ? (
                <div>
                  The member has scheduled or completed all their visits.
                </div>
              ) : (
                <MemberVisitsList
                  member={member}
                  onReload={reloadVisits}
                  sortType="displayOrder"
                  visits={unscheduled}
                  visitType={PapiAppointmentStatus.UNSCHEDULED}
                />
              )}
            </div>
            <div>
              <ColumnTitle>Upcoming visits</ColumnTitle>
              {hasCompleted ? (
                <div>
                  The member has completed all their visits on this plan.
                </div>
              ) : scheduled.length === 0 ? (
                <div>There are no upcoming scheduled visits on this plan.</div>
              ) : (
                <MemberVisitsList
                  onReload={reloadVisits}
                  sortType="startAtAsc"
                  visits={scheduled}
                />
              )}
            </div>
            <div>
              <ColumnTitle>Visit History</ColumnTitle>
              {completed.length === 0 ? (
                <div>There are no completed visits on this plan.</div>
              ) : (
                <MemberVisitsList
                  onReload={reloadVisits}
                  sortType="startAtDesc"
                  visits={completed}
                />
              )}
            </div>
          </Columns>
        </>
      )}
    </Body>
  );
};

const Body = styled.div`
  background: #fff;
  padding: ${theme.space.xxl};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PlanSelect = styled.div`
  display: flex;
`;

const PlanSelectWrapper = styled.div`
  align-items: center;
  flex: 0 0 300px;
  width: 350px;
`;

const AddVisitButton = styled.div<{ disabled?: boolean }>`
  align-items: center;
  color: ${(props) =>
    props.disabled ? theme.color.middleGrey : theme.color.parsleyGreen};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  i {
    margin-right: 5px;
  }
  min-width: 54px;
`;

const PlanSelectLabel = styled.div`
  margin-right: 10px;
  padding: 6px 0px;
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    flex: 0 0 32%;
  }
`;

const ColumnTitle = styled.h6`
  border-bottom: 1px solid #848489;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  padding-bottom: 10px;
`;

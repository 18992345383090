import gql from 'graphql-tag';

export const PAYMENT_CARD_DELETE_MUTATION = gql`
  mutation PaymentCardDelete_Mutation(
    $paymentMethodId: String!
    $personId: ID!
  ) {
    deleteCardPaymentMethod(
      paymentMethodId: $paymentMethodId
      personId: $personId
    )
  }
`;

export const PAYMENT_CARD_DELETE_UPDATE_QUERY = gql`
  query PaymentCardDelete_UpdateQuery($personID: ID!) {
    member: getPerson(id: $personID) {
      id
      cardPaymentMethods {
        id
        expiryMonth
        expiryYear
        brand
        last4Digits
      }
    }
  }
`;

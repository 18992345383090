/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum PapiAcuityInstance {
  ACUITY_LA = "ACUITY_LA",
  ACUITY_NYC = "ACUITY_NYC",
  ACUITY_SF = "ACUITY_SF",
}

export enum PapiAppointmentCategory {
  CARE_MANAGER_CHECK_IN = "CARE_MANAGER_CHECK_IN",
  COACHING_CHECK_IN = "COACHING_CHECK_IN",
  COACHING_FOLLOW_UP = "COACHING_FOLLOW_UP",
  COACHING_INITIAL = "COACHING_INITIAL",
  COACHING_INITIAL_PROGRAM = "COACHING_INITIAL_PROGRAM",
  COACHING_TRANSFER_OF_CARE = "COACHING_TRANSFER_OF_CARE",
  CONSULTATION = "CONSULTATION",
  LAB = "LAB",
  LAB_EXTERNAL = "LAB_EXTERNAL",
  MEDICAL_ASSESSMENT = "MEDICAL_ASSESSMENT",
  MEDICAL_FIRST_FOLLOW_UP = "MEDICAL_FIRST_FOLLOW_UP",
  MEDICAL_FOLLOW_UP = "MEDICAL_FOLLOW_UP",
  MEDICAL_INITIAL = "MEDICAL_INITIAL",
  MEDICAL_INITIAL_PROGRAM = "MEDICAL_INITIAL_PROGRAM",
  MEDICAL_TRANSFER_OF_CARE = "MEDICAL_TRANSFER_OF_CARE",
  NON_STANDARD = "NON_STANDARD",
}

export enum PapiAppointmentFormat {
  FORMAT_UNSPECIFIED = "FORMAT_UNSPECIFIED",
  IN_PERSON = "IN_PERSON",
  VIRTUAL = "VIRTUAL",
}

export enum PapiAppointmentGroup {
  CAREMANAGER = "CAREMANAGER",
  COACHING = "COACHING",
  CONSULTATION = "CONSULTATION",
  GROUP_UNSPECIFIED = "GROUP_UNSPECIFIED",
  LAB = "LAB",
  MEDICAL = "MEDICAL",
}

export enum PapiAppointmentStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  DELETED = "DELETED",
  NO_SHOW = "NO_SHOW",
  RESCHEDULED = "RESCHEDULED",
  SCHEDULED = "SCHEDULED",
  STATUS_UNSPECIFIED = "STATUS_UNSPECIFIED",
  UNSCHEDULED = "UNSCHEDULED",
}

export enum PapiBillingPeriod {
  DAY = "DAY",
  HALF_YEAR = "HALF_YEAR",
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  TRIMESTER = "TRIMESTER",
  YEAR = "YEAR",
}

export enum PapiConsumerType {
  CONSUMER_TYPE_UNSPECIFIED = "CONSUMER_TYPE_UNSPECIFIED",
  DIRECT_CONSUMER = "DIRECT_CONSUMER",
  ENTERPRISE_PARTNER = "ENTERPRISE_PARTNER",
}

export enum PapiCouponApplication {
  FOREVER = "FOREVER",
  ONCE = "ONCE",
}

export enum PapiEmploymentType {
  CONTRACTOR = "CONTRACTOR",
  FULL_TIME = "FULL_TIME",
}

/**
 * How an item is categorized after triage
 */
export enum PapiInboxItemCategory {
  ADMINISTRATIVE = "ADMINISTRATIVE",
  CONSULTS = "CONSULTS",
  INVOICES = "INVOICES",
  LAB_RESULTS = "LAB_RESULTS",
  MEDICAL_HISTORY = "MEDICAL_HISTORY",
  MISC = "MISC",
  RADIOLOGY = "RADIOLOGY",
}

/**
 * Specific source categorization - which integration generated an item.
 */
export enum PapiInboxItemSource {
  HEALTH_GORILLA_DIAGNOSTIC_REPORT = "HEALTH_GORILLA_DIAGNOSTIC_REPORT",
  HEALTH_GORILLA_EFAX = "HEALTH_GORILLA_EFAX",
  PERSON_UPLOAD = "PERSON_UPLOAD",
}

/**
 * General source categorization
 */
export enum PapiInboxItemSourceCategory {
  EFAX = "EFAX",
  LABORATORY = "LABORATORY",
  UPLOAD = "UPLOAD",
}

/**
 * Where an item is in the triage process
 */
export enum PapiInboxItemTriageStatus {
  ARCHIVED = "ARCHIVED",
  DONE = "DONE",
  NEW = "NEW",
  TRASHED = "TRASHED",
}

export enum PapiInsuranceType {
  CASH_PAY = "CASH_PAY",
  IN_NETWORK = "IN_NETWORK",
  OUT_OF_NETWORK = "OUT_OF_NETWORK",
  PARTNER_BILLING = "PARTNER_BILLING",
}

export enum PapiMembershipActivityStatus {
  MEMBERSHIP_ACTIVE = "MEMBERSHIP_ACTIVE",
  MEMBERSHIP_COMPLIMENTARY = "MEMBERSHIP_COMPLIMENTARY",
  MEMBERSHIP_INACTIVE = "MEMBERSHIP_INACTIVE",
  MEMBERSHIP_ON_EXTENSION = "MEMBERSHIP_ON_EXTENSION",
  MEMBERSHIP_PAUSED = "MEMBERSHIP_PAUSED",
  MEMBERSHIP_PENDING = "MEMBERSHIP_PENDING",
  MEMBERSHIP_PROSPECTIVE = "MEMBERSHIP_PROSPECTIVE",
  MEMBERSHIP_WAITLISTED = "MEMBERSHIP_WAITLISTED",
}

export enum PapiMembershipPaymentStatus {
  MEMBERSHIP_PAID = "MEMBERSHIP_PAID",
  MEMBERSHIP_UNPAID = "MEMBERSHIP_UNPAID",
}

export enum PapiPaymentStatus {
  DRAFT = "DRAFT",
  IN_ARREARS = "IN_ARREARS",
  OPEN = "OPEN",
  PAID = "PAID",
  PENDING = "PENDING",
  UNCOLLECTIBLE = "UNCOLLECTIBLE",
  UNKNOWN = "UNKNOWN",
  VOID = "VOID",
}

export enum PapiPlanPurchaseState {
  ENDED_CANCELLED = "ENDED_CANCELLED",
  ENDED_NATURALLY = "ENDED_NATURALLY",
  IN_FLIGHT_OPEN_ENDED = "IN_FLIGHT_OPEN_ENDED",
  PENDING_PLAN_START = "PENDING_PLAN_START",
  PENDING_SUBSCRIPTION_START = "PENDING_SUBSCRIPTION_START",
  TERMINATE_AT_END = "TERMINATE_AT_END",
  TERMINATING_AT_END = "TERMINATING_AT_END",
  TRANSITION_FROM_INSURANCE = "TRANSITION_FROM_INSURANCE",
  TRANSITION_TO_INSURANCE = "TRANSITION_TO_INSURANCE",
  UNABLE_TO_PROCESS_SUBSCRIPTION_CARD_ERROR = "UNABLE_TO_PROCESS_SUBSCRIPTION_CARD_ERROR",
}

export enum PapiPreEligibilityStatus {
  ELIGIBILITY_STATUS_UNKNOWN = "ELIGIBILITY_STATUS_UNKNOWN",
  ELIGIBLE = "ELIGIBLE",
  INELIGIBLE = "INELIGIBLE",
  INELIGIBLE_PAYER = "INELIGIBLE_PAYER",
}

export enum PapiProductType {
  ASSESSMENT = "ASSESSMENT",
  BASIC_HORMONE_ANALYSIS = "BASIC_HORMONE_ANALYSIS",
  COMPLETE_CARE_RENEWAL = "COMPLETE_CARE_RENEWAL",
  COMPLETE_CARE_Y1 = "COMPLETE_CARE_Y1",
  COMPLETE_CARE_Y1_POST_ASSESSMENT = "COMPLETE_CARE_Y1_POST_ASSESSMENT",
  COMPLETE_CARE_Y2_PLUS = "COMPLETE_CARE_Y2_PLUS",
  COMPREHENSIVE_GUT_ANALYSIS = "COMPREHENSIVE_GUT_ANALYSIS",
  COMPREHENSIVE_GUT_MEMBERSHIP = "COMPREHENSIVE_GUT_MEMBERSHIP",
  COMPREHENSIVE_HORMONE_ANALYSIS = "COMPREHENSIVE_HORMONE_ANALYSIS",
  COMPREHENSIVE_HORMONE_MEMBERSHIP = "COMPREHENSIVE_HORMONE_MEMBERSHIP",
  COMPREHENSIVE_HORMONE_PLAN = "COMPREHENSIVE_HORMONE_PLAN",
  ESSENTIAL_CARE = "ESSENTIAL_CARE",
  EXTENSION = "EXTENSION",
  FOLLOW_UP_ASSESSMENT = "FOLLOW_UP_ASSESSMENT",
  FOUNDATION = "FOUNDATION",
  FOUNDING_MEMBERSHIP = "FOUNDING_MEMBERSHIP",
  FREE_TRIAL = "FREE_TRIAL",
  HEALTH_RISK_ASSESSMENT = "HEALTH_RISK_ASSESSMENT",
  HORMONE_MEMBERSHIP = "HORMONE_MEMBERSHIP",
  LABS_PLUS_COACHING = "LABS_PLUS_COACHING",
  MEDICAL_ONLY = "MEDICAL_ONLY",
  MEDICAL_PLUS_COACHING = "MEDICAL_PLUS_COACHING",
  MENOPAUSE_LIFECYCLE_HORMONE_ANALYSIS = "MENOPAUSE_LIFECYCLE_HORMONE_ANALYSIS",
  MENOPAUSE_LIFECYCLE_HORMONE_MEMBERSHIP = "MENOPAUSE_LIFECYCLE_HORMONE_MEMBERSHIP",
  MERIWETHER = "MERIWETHER",
  NEW_PROGRAMS_LITE = "NEW_PROGRAMS_LITE",
  NO_PRODUCT = "NO_PRODUCT",
  PEDIATRICS = "PEDIATRICS",
  PEDIATRICS_RENEWAL = "PEDIATRICS_RENEWAL",
  SHORT_PROGRAM = "SHORT_PROGRAM",
  TOTAL_HEALTH = "TOTAL_HEALTH",
  VIRTUAL_ASSESSMENT = "VIRTUAL_ASSESSMENT",
  VIRTUAL_COMPLETE_CARE_Y1 = "VIRTUAL_COMPLETE_CARE_Y1",
  VIRTUAL_COMPLETE_CARE_Y2_PLUS = "VIRTUAL_COMPLETE_CARE_Y2_PLUS",
  WAITLIST_ASSESSMENT = "WAITLIST_ASSESSMENT",
  WAITLIST_COMPLETE_CARE = "WAITLIST_COMPLETE_CARE",
  WAITLIST_PEDIATRICS = "WAITLIST_PEDIATRICS",
  WAITLIST_SHORT_PROGRAM = "WAITLIST_SHORT_PROGRAM",
  WAITLIST_VIRTUAL_ASSESSMENT = "WAITLIST_VIRTUAL_ASSESSMENT",
  WAITLIST_VIRTUAL_COMPLETE_CARE = "WAITLIST_VIRTUAL_COMPLETE_CARE",
}

export enum PapiProfessionalSuffix {
  DNP = "DNP",
  DO = "DO",
  MD = "MD",
  MPH = "MPH",
  NP = "NP",
  PAC = "PAC",
  PHD = "PHD",
  RN = "RN",
}

export enum PapiProviderAttrType {
  IS_MEDICARE_OPTED_OUT = "IS_MEDICARE_OPTED_OUT",
  MAX_PATIENT_FACING_HOURS = "MAX_PATIENT_FACING_HOURS",
  WORK_DAYS_PER_WEEK = "WORK_DAYS_PER_WEEK",
}

export enum PapiProviderType {
  CARE_MANAGER = "CARE_MANAGER",
  CARE_MANAGER_PROVIDER_GROUP = "CARE_MANAGER_PROVIDER_GROUP",
  CLINICAL_COORDINATOR = "CLINICAL_COORDINATOR",
  DOCTOR = "DOCTOR",
  HEALTH_COACH = "HEALTH_COACH",
  MEMBER_ADVOCATE = "MEMBER_ADVOCATE",
  MXA = "MXA",
  MX_PROVIDER_GROUP = "MX_PROVIDER_GROUP",
  NURSE = "NURSE",
  PEDIATRICIAN = "PEDIATRICIAN",
  PHLEBOTOMIST = "PHLEBOTOMIST",
}

export enum PapiQuestionnaireStatus {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  STARTED = "STARTED",
  SUBMITTED = "SUBMITTED",
  UNSTARTED = "UNSTARTED",
}

export enum PapiRelationship {
  COWORKER = "COWORKER",
  FRIEND = "FRIEND",
  OTHER_RELATIONSHIP = "OTHER_RELATIONSHIP",
  PARENT = "PARENT",
  PARTNER = "PARTNER",
  RELATIVE = "RELATIVE",
  SIBLING = "SIBLING",
  UNSPECIFIED_RELATIONSHIP = "UNSPECIFIED_RELATIONSHIP",
}

export enum PapiRole {
  PARSLEY_ADMIN = "PARSLEY_ADMIN",
  PARSLEY_ANONYMOUS = "PARSLEY_ANONYMOUS",
  PARSLEY_MEMBER = "PARSLEY_MEMBER",
  PARSLEY_STAFF = "PARSLEY_STAFF",
  PARSLEY_SUPERADMIN = "PARSLEY_SUPERADMIN",
  PARSLEY_USER = "PARSLEY_USER",
}

export enum PapiSex {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
}

export enum PapiStripeSubscriptionStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  INCOMPLETE = "INCOMPLETE",
  INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
  PAST_DUE = "PAST_DUE",
  TRIALING = "TRIALING",
  UNPAID = "UNPAID",
}

export enum PapiTransferOfCareTypeInput {
  CLINICAL = "CLINICAL",
  COACHING = "COACHING",
}

export enum PartnerSearchType {
  GROUP = "GROUP",
  PARTNER = "PARTNER",
}

export interface LabPreorderItemFilter {
  order?: OrderField[] | null;
  pagination?: PaginationRequest | null;
  purchaseID?: string | null;
  purchasePeriodID?: string | null;
  includeAlreadyOrdered?: boolean | null;
  dedupe?: boolean | null;
}

export interface OrderField {
  field?: string | null;
  direction?: OrderDirection | null;
}

export interface PaginationRequest {
  after?: any | null;
  first?: number | null;
  before?: any | null;
  last?: number | null;
}

export interface PapiAcceptQuestionnairesInput {
  ids: string[];
}

export interface PapiAddCouponToPurchaseInput {
  couponSlug: string;
  purchaseID: string;
}

export interface PapiAddFollowOnPlanInput {
  currentPurchaseID: string;
  planID: string;
  centerID: string;
  couponSlug?: string | null;
}

export interface PapiAddInsuranceSubscriberInput {
  personId: string;
  relationshipToPatient: string;
  policyNumber: string;
  payerId: string;
  groupId: string;
  planId: string;
  planStartDate?: string | null;
  planEndDate?: string | null;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  dateOfBirth: string;
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  state: string;
  zipcode: string;
  sex: string;
  country: string;
  copayAmountInCents?: number | null;
}

export interface PapiAddProviderInput {
  acuityID?: string | null;
  acuityInstance?: PapiAcuityInstance | null;
  biologicalSex: PapiSex;
  centers: PapiProviderCenterInput[];
  email: string;
  firstName: string;
  helpScoutID?: string | null;
  lastName: string;
  middleName?: string | null;
  photoURL?: string | null;
  professionalSuffix?: PapiProfessionalSuffix | null;
  sanityID?: string | null;
  takeShapeID?: string | null;
  type: PapiProviderType;
  employmentType?: PapiEmploymentType | null;
  products?: PapiProductType[] | null;
}

export interface PapiArchiveInboxItemInput {
  id: string;
  category: PapiInboxItemCategory;
}

export interface PapiCancelPurchaseInput {
  personID: string;
  purchaseID: string;
  endDate?: string | null;
  cancelFollowingPurchases?: boolean | null;
  cancelUpcomingAppointments?: boolean | null;
  overrideToEndAfterNextBill?: boolean | null;
}

export interface PapiCategorizeInboxItemInput {
  id: string;
  category: PapiInboxItemCategory;
  personID?: string | null;
  reviewerID?: string | null;
}

export interface PapiCouponDiscountInput {
  amountOffCents?: number | null;
  percentOff?: number | null;
}

export interface PapiCouponDurationInput {
  apply?: PapiCouponApplication | null;
  months?: number | null;
}

export interface PapiCreateAppointmentInput {
  centerID: string;
  personID: string;
  planPurchaseID: string;
  typeID: string;
  duration: number;
  isComplimentary: boolean;
  notes?: string | null;
}

export interface PapiCreateCouponInput {
  slug: string;
  description: string;
  discount: PapiCouponDiscountInput;
  duration: PapiCouponDurationInput;
  expires?: string | null;
  maxRedemptions?: number | null;
  centerIDs?: string[] | null;
  products?: PapiProductType[] | null;
  planIDs?: string[] | null;
}

export interface PapiCreatePersonInput {
  email: string;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  dateOfBirth: string;
  biologicalSex: PapiSex;
  genderIdentity?: string | null;
  ssn?: string | null;
  phoneNumber?: string | null;
  centerID: string;
  addressLine1?: string | null;
  addressLine2?: string | null;
  addressCity?: string | null;
  addressState?: string | null;
  addressPostalCode: string;
  patientAgreementAcceptedAt?: string | null;
  insurancePayer?: string | null;
  insuranceGroupID?: string | null;
  insuranceMemberID?: string | null;
  hipaaAuthorizationAcceptedAt?: string | null;
  termsOfUseAcceptedAt?: string | null;
  membershipTermsAcceptedAt?: string | null;
  selfPaymentOfServicesTermsAcceptedAt?: string | null;
  privacyPolicyAcceptedAt?: string | null;
  supplementAcceptedAt?: string | null;
  clinicalMembershipAgreementAcceptedAt?: string | null;
  membershipAgreementAcceptedAt?: string | null;
  assignmentOfBenefitsAcceptedAt?: string | null;
  creditCardAuthAcceptedAt?: string | null;
  role?: PapiRole | null;
  sendPasswordReset: boolean;
  recaptcha?: PapiRecaptchaInput | null;
  noticePrivacyPracticesAcceptedAt?: string | null;
  telehealthInformedConsentAcceptedAt?: string | null;
  telehealthAgreementAcceptedAt?: string | null;
  assignedDoctorID?: string | null;
  assignedCoachID?: string | null;
  onboardingFlowSlug?: string | null;
  analyticsID?: string | null;
  enrolledBy?: string | null;
}

export interface PapiCreatePlanInput {
  displayName: string;
  description?: string | null;
  productType: PapiProductType;
  billingPeriod: PapiBillingPeriod;
  numberOfBillingCycles?: number | null;
  paymentRateInCents: number;
  minPersonAgeYears: number;
  maxPersonAgeYears?: number | null;
  defaultFollowOnPlanID?: string | null;
  commitmentLength?: PapiBillingPeriod | null;
  joinPurchaseAllowedCenterIDs: string[];
}

export interface PapiEnrollFromWaitlistInput {
  purchaseID: string;
  addDefaultFollowOn?: boolean | null;
  startTime?: string | null;
  newPlanID?: string | null;
  couponSlug?: string | null;
}

export interface PapiExtendPurchaseInput {
  personID: string;
  currentPurchaseID: string;
  planID: string;
  centerID: string;
}

export interface PapiFollowOnPurchaseInput {
  planID: string;
  centerID: string;
  couponSlug?: string | null;
}

export interface PapiMakeNewB2BPurchaseInput {
  payorID: string;
  planID: string;
  centerID: string;
  startTime?: string | null;
  personID: string;
  couponSlug?: string | null;
  partnerSlug: string;
}

export interface PapiMakeNewPurchaseInput {
  payorID: string;
  planID: string;
  centerID: string;
  startTime?: string | null;
  couponSlug?: string | null;
  personID: string;
  followOnPurchase?: PapiFollowOnPurchaseInput | null;
  addDefaultFollowOn?: boolean | null;
  iterateOverFollowOns?: boolean | null;
}

export interface PapiPausePurchaseInput {
  purchaseID: string;
  daysToPause: number;
  startTime?: string | null;
}

export interface PapiPersonEmergencyContactInput {
  personId: string;
  relationship: PapiRelationship;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email?: string | null;
}

export interface PapiProviderAttrInput {
  providerID: string;
  attr: PapiProviderAttrType;
  value: string;
}

export interface PapiProviderCenterInput {
  centerID: string;
  takingNewPatients: boolean;
}

export interface PapiProviderPayerCredentialsInput {
  payerID: string;
  centerID: string;
}

export interface PapiRecaptchaInput {
  token: string;
  ipAddress: string;
}

export interface PapiSetInvoicePaymentStatusInput {
  invoiceID: string;
  newStatus: PapiPaymentStatus;
}

export interface PapiTransferCareInput {
  personID: string;
  providerID?: string | null;
  currentProviderID?: string | null;
  transferType: PapiTransferOfCareTypeInput;
}

export interface PapiTransitionPlanPurchaseInput {
  personID: string;
  purchaseID: string;
  planID: string;
}

export interface PapiTrashInboxItemInput {
  id: string;
  personID?: string | null;
}

export interface PapiUpdateAppointmentInput {
  appointmentID: string;
  notes?: string | null;
  isComplimentary?: boolean | null;
}

export interface PapiUpdateCouponInput {
  slug: string;
  maxRedemptions?: number | null;
  redeemBy?: string | null;
  centerIDs?: string[] | null;
  productTypes?: PapiProductType[] | null;
  planIDs?: string[] | null;
}

export interface PapiUpdateInsuranceSubscriberInput {
  id: string;
  personId: string;
  relationshipToPatient: string;
  policyNumber: string;
  payerId: string;
  groupId: string;
  planId: string;
  planStartDate?: string | null;
  planEndDate?: string | null;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  dateOfBirth: string;
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  state: string;
  zipcode: string;
  sex: string;
  country: string;
  copayAmountInCents?: number | null;
}

export interface PapiUpdatePersonInput {
  id: string;
  email?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
  biologicalSex?: PapiSex | null;
  genderIdentity?: string | null;
  ssn?: string | null;
  phoneNumber?: string | null;
  centerID?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  addressCity?: string | null;
  addressState?: string | null;
  addressPostalCode?: string | null;
  patientAgreementAcceptedAt?: string | null;
  telehealthAgreementAcceptedAt?: string | null;
  insurancePayer?: string | null;
  insuranceGroupID?: string | null;
  insuranceMemberID?: string | null;
  hipaaAuthorizationAcceptedAt?: string | null;
  helpScoutID?: string | null;
  termsOfUseAcceptedAt?: string | null;
  membershipTermsAcceptedAt?: string | null;
  selfPaymentOfServicesTermsAcceptedAt?: string | null;
  privacyPolicyAcceptedAt?: string | null;
  supplementAcceptedAt?: string | null;
  clinicalMembershipAgreementAcceptedAt?: string | null;
  membershipAgreementAcceptedAt?: string | null;
  assignmentOfBenefitsAcceptedAt?: string | null;
  creditCardAuthAcceptedAt?: string | null;
  role?: PapiRole | null;
  enrolledBy?: string | null;
  newMDHQID?: string | null;
  noticePrivacyPracticesAcceptedAt?: string | null;
  telehealthInformedConsentAcceptedAt?: string | null;
  assignedDoctorID?: string | null;
  assignedCoachID?: string | null;
}

export interface PapiUpdatePlanJoinableCentersInput {
  ID: string;
  joinPurchaseAllowedCenterIDs: string[];
}

export interface PapiUpdateProviderInput {
  id: string;
  takeShapeID?: string | null;
  sanityID?: string | null;
  acuityID?: string | null;
  mdhqId?: string | null;
  helpScoutID?: string | null;
  careManagerID?: string | null;
  type?: PapiProviderType | null;
  employmentType?: PapiEmploymentType | null;
  email?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  professionalSuffix?: PapiProfessionalSuffix | null;
  biologicalSex?: PapiSex | null;
  photoURL?: string | null;
  acuityInstance?: PapiAcuityInstance | null;
  centers?: PapiProviderCenterInput[] | null;
  payerCredentials?: PapiProviderPayerCredentialsInput[] | null;
  products?: PapiProductType[] | null;
  targetPanelSize?: number | null;
}

export interface PartnerFilter {
  parentID?: string | null;
  pagination?: PaginationRequest | null;
  searchString?: string | null;
  type: PartnerSearchType;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

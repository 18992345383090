import { useMutation } from '@apollo/react-hooks';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { message } from 'antd';
import gql from 'graphql-tag';
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components/macro';

import {
  AForm,
  TFormShouldFinish,
} from 'app/components/deprecated/AForm/AForm';
import { theme } from 'app/styles/theme';
import {
  // eslint-disable-next-line
  attachPaymentMethod,
  attachPaymentMethodVariables,
} from 'app/types/generated/attachPaymentMethod';

import {
  setDefaultPaymentCard,
  setDefaultPaymentCardVariables,
} from 'app/types/generated/setDefaultPaymentCard';

import { displayErrors } from 'app/utils/app';
import { STRIPE_API_KEY } from 'constants/env';
import { STATUS_MESSAGE } from 'constants/message';
// eslint-disable-next-line
import {
  // eslint-disable-next-line
  createPaymentSetupIntent,
  createPaymentSetupIntentVariables,
} from '../../../types/generated/createPaymentSetupIntent';
import {
  PAYMENT_CARD_SET_DEFAULT_MUTATION,
  PAYMENT_CARD_SET_DEFAULT_REFETCH,
} from '../PaymentCardSetDefault/query';

// Types & constants ////////////////////////////////
interface Props {
  openText?: ReactNode;
  personID: string;
}

// const fonts = [
//   {
//     display: 'block',
//     family: 'EuclidCircular',
//     src: `url(${STATIC_FILES_URL}/fonts/euclid-circular-b/euclid-circular-b-regular.woff)`,
//     style: 'normal',
//     weight: '400',
//   },
// ];

export const CREATE_SETUP_INTENT_MUTATION = gql`
  mutation createPaymentSetupIntent($personId: ID!) {
    createPaymentSetupIntent(personId: $personId) {
      setupIntentClientSecret
    }
  }
`;

export const ATTACH_PAYMENT_METHOD_MUTATION = gql`
  mutation attachPaymentMethod($paymentMethodId: String!, $personId: ID!) {
    attachPaymentMethod(paymentMethodId: $paymentMethodId, personId: $personId)
  }
`;

const stripePromise = loadStripe(STRIPE_API_KEY);

const PaymentCardAdd: FC<Props> = ({
  openText = 'Add payment card',
  personID,
}) => {
  const [clientSecret, setClientSecret] = useState<string>();
  const [paymentMethod, setPaymentMethod] = useState<string>();

  const [attachPaymentMethod, { loading: mutationLoading }] = useMutation<
    attachPaymentMethod,
    attachPaymentMethodVariables
  >(ATTACH_PAYMENT_METHOD_MUTATION);

  const [
    setDefaultPaymentMethod,
    { loading: defaultMutationLoading },
  ] = useMutation<setDefaultPaymentCard, setDefaultPaymentCardVariables>(
    PAYMENT_CARD_SET_DEFAULT_MUTATION,
    {
      refetchQueries: [
        {
          query: PAYMENT_CARD_SET_DEFAULT_REFETCH,
          variables: { personID: personID },
        },
      ],
    }
  );

  const [createPaymentSetupIntent] = useMutation<
    createPaymentSetupIntent,
    createPaymentSetupIntentVariables
  >(CREATE_SETUP_INTENT_MUTATION);

  const elements = useElements();
  const stripe = useStripe();

  // Handle card payment if client secret exists
  useEffect(() => {
    const handleCardPayment = async (secret: string) => {
      const cardElement = elements?.getElement(CardElement);

      if (cardElement) {
        const stripeCardSetup = await stripe?.confirmCardSetup(secret, {
          payment_method: {
            card: cardElement,
          },
        });

        if (stripeCardSetup?.setupIntent) {
          const { payment_method } = stripeCardSetup?.setupIntent;
          setPaymentMethod(payment_method as string);
        }
      }
    };

    if (clientSecret) {
      handleCardPayment(clientSecret);
    }
  }, [clientSecret, elements, stripe]);

  useEffect(() => {
    const handleAttachPayment = async (
      paymentMethodId: string
    ): Promise<void> => {
      const { data } = await attachPaymentMethod({
        variables: {
          paymentMethodId,
          personId: personID,
        },
      });

      if (data.attachPaymentMethod) {
        message.success(STATUS_MESSAGE.paymentCardAdd.success);
        await setDefaultPaymentMethod({
          variables: {
            paymentMethodId,
            personId: personID,
          },
        });
      } else {
        message.warning(STATUS_MESSAGE.error.noApiResponse, 7);
      }
    };

    if (paymentMethod) {
      handleAttachPayment(paymentMethod);
    }
    // eslint-disable-next-line
  }, [attachPaymentMethod, paymentMethod, personID]);

  const onSave = async (
    inputIgnore: any,
    shouldFinish: TFormShouldFinish
  ): Promise<void> => {
    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const card = elements.getElement(CardElement);

      if (!card) {
        return;
      }

      // Create payment intent
      const { data: paymentSetupIntent } = await createPaymentSetupIntent({
        variables: {
          personId: personID,
        },
      });

      // Get setup intent client secret
      const {
        setupIntentClientSecret,
      } = paymentSetupIntent.createPaymentSetupIntent;

      if (paymentSetupIntent.createPaymentSetupIntent) {
        setClientSecret(setupIntentClientSecret);
        shouldFinish(true);
        return;
      }
      message.error(STATUS_MESSAGE.stripe.error.noToken);
      shouldFinish(false);
      return;
    } catch (err) {
      displayErrors(err, STATUS_MESSAGE.paymentCardAdd.error.general);
      shouldFinish(false);
    }
  };

  return (
    <AForm
      onSave={onSave}
      openAs="modal"
      openText={openText}
      saveText="Add"
      saving={mutationLoading || defaultMutationLoading}
      savingText="Adding"
      title="New payment card"
    >
      {() => (
        <>
          The new card will be set as the default payment method, and it will be
          charged automatically for membership fees.
          <CardElementStyled
            className="ant-input"
            style={{ base: { fontFamily: '"EuclidCircular", sans-serif' } }}
          />
        </>
      )}
    </AForm>
  );
};

// Styled components ////////////////////////////////
const CardElementStyled = styled(CardElement)`
  &&& {
    display: inherit;
    height: inherit;
    margin: ${theme.space.m} 0;
    padding: ${theme.space.m};
  }
`;

function PaymentCardAddStripeContainer(
  props: PropsWithChildren<Props>
): JSX.Element {
  return (
    <Elements
      options={{
        currency: 'usd',
        mode: 'setup',
      }}
      stripe={stripePromise}
    >
      <PaymentCardAdd {...props} />
    </Elements>
  );
}
export { PaymentCardAddStripeContainer as PaymentCardAdd };

import { DateTime } from 'luxon';

import {
  PapiBillingPeriod,
  PapiMembershipActivityStatus,
  PapiMembershipPaymentStatus,
  PapiPaymentStatus,
  PapiProductType,
  PapiRole,
  PapiSex,
  PapiStripeSubscriptionStatus,
  PapiInsuranceType,
  PapiPlanPurchaseState,
} from 'app/types/generated/globalTypes';
import { TYPENAMES } from 'app/types/papi';

import { MOCK_CENTERS } from './center';
import { MOCK_PLANS } from './plan';
import { MOCK_PROVIDERS } from './provider';

export const MOCK_LEGACY_MEMBER = {
  __typename: TYPENAMES.Person,
  addressState: 'NJ',
  firstName: 'Linda',
  id: 'php-43110',
  lastName: 'Belcher',
  middleName: null,
};

export const MOCK_MEMBER = {
  __typename: TYPENAMES.PapiPerson,
  addressCity: 'Ocean City',
  addressLine1: '1368 Boardwalk',
  addressLine2: 'C6',
  addressPostalCode: '08226',
  addressState: 'NJ',
  allPlans: {
    __typename: TYPENAMES.PapiPlanPurchaseConnection,
    edges: [
      {
        __typename: TYPENAMES.PapiPlanPurchaseEdge,
        node: {
          __typename: TYPENAMES.PapiPlanPurchase,
          center: MOCK_CENTERS[0],
          coupon: null,
          endDateIncludingExtensions: null,
          endTime: null,
          extensionOf: null,
          id: 'futureplan',
          insuranceType: PapiInsuranceType.CASH_PAY,
          latestPause: null,
          legacyHintMembershipID: null,
          member: {
            __typename: TYPENAMES.PapiPerson,
            id: 'lbelcher',
          },
          nextBillDate: DateTime.local().plus({ months: 1 }).toISO(),
          nextRenewalDate: null,
          paymentStatus: PapiPaymentStatus.DRAFT,
          payor: { __typename: TYPENAMES.PapiPerson, id: 'lbelcher' },
          plan: MOCK_PLANS.find(
            (p) => p.product.type === PapiProductType.COMPLETE_CARE_Y2_PLUS
          )!,
          purchaseState: PapiPlanPurchaseState.ENDED_NATURALLY,
          renewalPeriods: [],
          startTime: DateTime.local().plus({ months: 1 }).toISO(),
          stripeSubscriptionStatus: PapiStripeSubscriptionStatus.ACTIVE,
        },
      },
      {
        __typename: TYPENAMES.PapiPlanPurchaseEdge,
        node: {
          __typename: TYPENAMES.PapiPlanPurchase,
          center: MOCK_CENTERS[0],
          coupon: null,
          endDateIncludingExtensions: DateTime.local()
            .plus({ months: 1 })
            .toISO(),
          endTime: DateTime.local().plus({ months: 1 }).toISO(),
          extensionOf: null,
          id: 'currplan',
          insuranceType: PapiInsuranceType.CASH_PAY,
          latestPause: null,
          legacyHintMembershipID: null,
          member: {
            __typename: TYPENAMES.PapiPerson,
            id: 'lbelcher',
          },
          nextBillDate: DateTime.local().plus({ days: 1 }).toISO(),
          nextRenewalDate: null,
          paymentStatus: PapiPaymentStatus.PENDING,
          payor: { __typename: TYPENAMES.PapiPerson, id: 'lbelcher' },
          plan:
            MOCK_PLANS.find(
              (p) =>
                p.product.type === PapiProductType.COMPLETE_CARE_Y1 &&
                p.billingPeriod === PapiBillingPeriod.MONTH
            ) || MOCK_PLANS[0],
          purchaseState: PapiPlanPurchaseState.ENDED_NATURALLY,
          renewalPeriods: [],
          startTime: DateTime.local().minus({ months: 11 }).toISO(),
          stripeSubscriptionStatus: PapiStripeSubscriptionStatus.ACTIVE,
        },
      },
      {
        __typename: TYPENAMES.PapiPlanPurchaseEdge,
        node: {
          __typename: TYPENAMES.PapiPlanPurchase,
          center: MOCK_CENTERS[0],
          coupon: null,
          endDateIncludingExtensions: DateTime.local()
            .minus({ months: 11 })
            .toISO(),
          endTime: DateTime.local().minus({ months: 11 }).toISO(),
          extensionOf: {
            __typename: TYPENAMES.PapiPlanPurchase,
            center: MOCK_CENTERS[0],
            coupon: null,
            endDateIncludingExtensions: DateTime.local()
              .minus({ months: 11 })
              .toISO(),
            endTime: DateTime.local().minus({ months: 14 }).toISO(),
            id: 'pastplan',
            insuranceType: PapiInsuranceType.CASH_PAY,
            latestPause: null,
            legacyHintMembershipID: 'mem-BOEbDW4ylQgb',
            member: {
              __typename: TYPENAMES.PapiPerson,
              id: 'lbelcher',
            },
            nextBillDate: null,
            nextRenewalDate: null,
            paymentStatus: PapiPaymentStatus.PAID,
            payor: { __typename: TYPENAMES.PapiPerson, id: 'lbelcher' },
            plan: {
              ...(MOCK_PLANS.find(
                (p) =>
                  p.product.type === PapiProductType.ASSESSMENT &&
                  p.billingPeriod === PapiBillingPeriod.HALF_YEAR
              ) || MOCK_PLANS[0]),
              displayName: 'Legacy - Hint Assessment',
              id: 'legacyplan',
            },
            purchaseState: PapiPlanPurchaseState.ENDED_NATURALLY,
            renewalPeriods: [],
            startTime: DateTime.local().minus({ months: 20 }).toISO(),
            stripeSubscriptionStatus: PapiStripeSubscriptionStatus.CANCELED,
          },
          id: 'pastext',
          insuranceType: PapiInsuranceType.CASH_PAY,
          latestPause: null,
          legacyHintMembershipID: null,
          member: {
            __typename: TYPENAMES.PapiPerson,
            id: 'lbelcher',
          },
          nextBillDate: null,
          nextRenewalDate: null,
          paymentStatus: PapiPaymentStatus.PAID,
          payor: { __typename: TYPENAMES.PapiPerson, id: 'lbelcher' },
          plan:
            MOCK_PLANS.find(
              (p) =>
                p.product.type === PapiProductType.EXTENSION &&
                p.numberOfBillingCycles === 3
            ) || MOCK_PLANS[0],
          purchaseState: PapiPlanPurchaseState.ENDED_NATURALLY,
          renewalPeriods: [],
          startTime: DateTime.local().minus({ months: 14 }).toISO(),
          stripeSubscriptionStatus: PapiStripeSubscriptionStatus.CANCELED,
        },
      },
      {
        __typename: TYPENAMES.PapiPlanPurchaseEdge,
        node: {
          __typename: TYPENAMES.PapiPlanPurchase,
          center: MOCK_CENTERS[0],
          coupon: null,
          endDateIncludingExtensions: DateTime.local()
            .minus({ months: 11 })
            .toISO(),
          endTime: DateTime.local().minus({ months: 14 }).toISO(),
          id: 'pastplan',
          insuranceType: PapiInsuranceType.CASH_PAY,
          latestPause: null,
          legacyHintMembershipID: 'mem-BOEbDW4ylQgb',
          member: {
            __typename: TYPENAMES.PapiPerson,
            id: 'lbelcher',
          },
          nextBillDate: null,
          nextRenewalDate: null,
          paymentStatus: PapiPaymentStatus.PAID,
          payor: { __typename: TYPENAMES.PapiPerson, id: 'lbelcher' },
          plan: {
            ...(MOCK_PLANS.find(
              (p) =>
                p.product.type === PapiProductType.ASSESSMENT &&
                p.billingPeriod === PapiBillingPeriod.HALF_YEAR
            ) || MOCK_PLANS[0]),
            displayName: 'Legacy - Hint Assessment',
            id: 'legacyplan',
          },
          purchaseState: PapiPlanPurchaseState.ENDED_NATURALLY,
          renewalPeriods: [],
          startTime: DateTime.local().minus({ months: 20 }).toISO(),
          stripeSubscriptionStatus: PapiStripeSubscriptionStatus.CANCELED,
        },
      },
    ],
  },
  biologicalSex: 'FEMALE' as PapiSex,
  center: MOCK_CENTERS[0],
  currentPaymentCard: {
    __typename: TYPENAMES.PapiPaymentCard,
    deleted: false,
    expiryMonth: 4,
    expiryYear: 2023,
    id: 'defcard',
    last4Digits: '1452',
    name: 'American Express - credit',
    personID: 'lbelcher',
  },
  currentPlan: {
    __typename: TYPENAMES.PapiPlanPurchase,
    center: MOCK_CENTERS[0],
    coupon: null,
    endDateIncludingExtensions: DateTime.local().plus({ months: 1 }).toISO(),
    endTime: DateTime.local().plus({ months: 1 }).toISO(),
    extensionOf: null,
    id: 'currplan',
    insuranceType: PapiInsuranceType.CASH_PAY,
    latestPause: null,
    legacyHintMembershipID: null,
    member: {
      __typename: TYPENAMES.PapiPerson,
      id: 'lbelcher',
    },
    nextBillDate: DateTime.local().plus({ days: 1 }).toISO(),
    nextRenewalDate: DateTime.local().plus({ months: 1 }).toISO(),
    paymentStatus: PapiPaymentStatus.PENDING,
    payor: { __typename: TYPENAMES.PapiPerson, id: 'lbelcher' },
    plan:
      MOCK_PLANS.find(
        (p) =>
          p.product.type === PapiProductType.COMPLETE_CARE_Y1 &&
          p.billingPeriod === PapiBillingPeriod.MONTH
      ) || MOCK_PLANS[0],
    purchaseState: PapiPlanPurchaseState.ENDED_NATURALLY,
    renewalPeriods: [],
    startTime: DateTime.local().minus({ months: 11 }).toISO(),
    stripeSubscriptionStatus: PapiStripeSubscriptionStatus.ACTIVE,
  },
  dateOfBirth: '1973-05-03',
  email: 'linda.belcher@gmail.com',
  enrolledBy: 'User Test',
  firstName: 'Linda',
  fullName: 'Linda Belcher',
  genderIdentity: 'Woman',
  helpScoutID: 'helpscoutID',
  hipaaAuthorizationAcceptedAt: null,
  hubspotID: '79250705',
  id: 'lbelcher',
  insuranceGroupID: '878001-335-77766',
  insuranceMemberID: 'IDC7C88L00145664',
  insurancePayer: 'Blue Cross Blue Shield',
  invoices: [
    {
      __typename: TYPENAMES.PapiInvoice,
      createdAt: DateTime.local().toISO(),
      currency: 'USD',
      description: null,
      hostedInvoiceURL:
        'https://pay.stripe.com/invoice/invst_5hZDvkTQ0KXhNV1g9bLlHLXkoE',
      id: 'in_1EozSJFhEZIEp76VCkVnygpw',
      isLegacy: false,
      latestCharge: {
        __typename: TYPENAMES.PapiCharge,
        amountRefunded: null,
        failureCode: null,
        failureMessage: null,
        id: 'in_1EozSJFhEZIEp76VCkVnygpwcharge',
        refunded: false,
      },
      number: '004ABBA6-0012',
      periodStart: DateTime.local().toISODate(),
      status: PapiPaymentStatus.OPEN,
      total: 15000,
    },
    {
      __typename: TYPENAMES.PapiInvoice,
      createdAt: DateTime.local().minus({ months: 1 }).toISO(),
      currency: 'USD',
      description: null,
      hostedInvoiceURL:
        'https://pay.stripe.com/invoice/invst_5hZDvkTQ0KXhNV1g9bLlHLXkoE',
      id: 'inv-HuOO2uDL2TF1',
      isLegacy: false,
      latestCharge: {
        __typename: TYPENAMES.PapiCharge,
        amountRefunded: null,
        failureCode: null,
        failureMessage: null,
        id: 'inv-HuOO2uDL2TF1charge',
        refunded: false,
      },
      number: '004ABBA6-0011',
      periodStart: DateTime.local().minus({ months: 1 }).toISODate(),
      status: PapiPaymentStatus.PAID,
      total: 15000,
    },
    {
      __typename: TYPENAMES.PapiInvoice,
      createdAt: DateTime.local().minus({ months: 2 }).toISO(),
      currency: 'USD',
      description: null,
      hostedInvoiceURL:
        'https://pay.stripe.com/invoice/invst_5hZDvkTQ0KXhNV1g9bLlHLXkoE',
      id: 'inv-HuOO2uDL2TF2',
      isLegacy: false,
      latestCharge: {
        __typename: TYPENAMES.PapiCharge,
        amountRefunded: null,
        failureCode: null,
        failureMessage: null,
        id: 'inv-HuOO2uDL2TF2charge',
        refunded: false,
      },
      number: '004ABBA6-0010',
      periodStart: DateTime.local().minus({ months: 2 }).toISODate(),
      status: PapiPaymentStatus.PAID,
      total: 15000,
    },
    {
      __typename: TYPENAMES.PapiInvoice,
      createdAt: DateTime.local().minus({ months: 3 }).toISO(),
      currency: 'USD',
      description: null,
      hostedInvoiceURL:
        'https://pay.stripe.com/invoice/invst_5hZDvkTQ0KXhNV1g9bLlHLXkoE',
      id: 'inv-HuOO2uDL2TF3',
      isLegacy: false,
      latestCharge: {
        __typename: TYPENAMES.PapiCharge,
        amountRefunded: null,
        failureCode: null,
        failureMessage: null,
        id: 'inv-HuOO2uDL2TF3charge',
        refunded: false,
      },
      number: '004ABBA6-0009',
      periodStart: DateTime.local().minus({ months: 3 }).toISODate(),
      status: PapiPaymentStatus.PAID,
      total: 15000,
    },
    {
      __typename: TYPENAMES.PapiInvoice,
      createdAt: DateTime.local().minus({ months: 4 }).toISO(),
      currency: 'USD',
      description: null,
      hostedInvoiceURL:
        'https://pay.stripe.com/invoice/invst_5hZDvkTQ0KXhNV1g9bLlHLXkoE',
      id: 'inv-HuOO2uDL2TF4',
      isLegacy: false,
      latestCharge: {
        __typename: TYPENAMES.PapiCharge,
        amountRefunded: null,
        failureCode: null,
        failureMessage: null,
        id: 'inv-HuOO2uDL2TF4charge',
        refunded: false,
      },
      number: '004ABBA6-0008',
      periodStart: DateTime.local().minus({ months: 4 }).toISODate(),
      status: PapiPaymentStatus.PAID,
      total: 15000,
    },
    {
      __typename: TYPENAMES.PapiInvoice,
      createdAt: DateTime.local().minus({ months: 5 }).toISO(),
      currency: 'USD',
      description: null,
      hostedInvoiceURL:
        'https://pay.stripe.com/invoice/invst_5hZDvkTQ0KXhNV1g9bLlHLXkoE',
      id: 'inv-HuOO2uDL2TF5',
      isLegacy: false,
      latestCharge: {
        __typename: TYPENAMES.PapiCharge,
        amountRefunded: null,
        failureCode: null,
        failureMessage: null,
        id: 'inv-HuOO2uDL2TF5charge',
        refunded: false,
      },
      number: '004ABBA6-0007',
      periodStart: DateTime.local().minus({ months: 5 }).toISODate(),
      status: PapiPaymentStatus.PAID,
      total: 15000,
    },
    {
      __typename: TYPENAMES.PapiInvoice,
      createdAt: DateTime.local().minus({ months: 6 }).toISO(),
      currency: 'USD',
      description: null,
      hostedInvoiceURL:
        'https://pay.stripe.com/invoice/invst_5hZDvkTQ0KXhNV1g9bLlHLXkoE',
      id: 'inv-HuOO2uDL2TF6',
      isLegacy: false,
      latestCharge: {
        __typename: TYPENAMES.PapiCharge,
        amountRefunded: null,
        failureCode: null,
        failureMessage: null,
        id: 'inv-HuOO2uDL2TF6charge',
        refunded: false,
      },
      number: '004ABBA6-0006',
      periodStart: DateTime.local().minus({ months: 6 }).toISODate(),
      status: PapiPaymentStatus.PAID,
      total: 15000,
    },
    {
      __typename: TYPENAMES.PapiInvoice,
      createdAt: DateTime.local().minus({ months: 7 }).toISO(),
      currency: 'USD',
      description: null,
      hostedInvoiceURL:
        'https://pay.stripe.com/invoice/invst_5hZDvkTQ0KXhNV1g9bLlHLXkoE',
      id: 'inv-HuOO2uDL2TF7',
      isLegacy: false,
      latestCharge: {
        __typename: TYPENAMES.PapiCharge,
        amountRefunded: null,
        failureCode: null,
        failureMessage: null,
        id: 'inv-HuOO2uDL2TF7charge',
        refunded: false,
      },
      number: '004ABBA6-0005',
      periodStart: DateTime.local().minus({ months: 7 }).toISODate(),
      status: PapiPaymentStatus.PAID,
      total: 15000,
    },
    {
      __typename: TYPENAMES.PapiInvoice,
      createdAt: DateTime.local().minus({ months: 8 }).toISO(),
      currency: 'USD',
      description: null,
      hostedInvoiceURL:
        'https://pay.stripe.com/invoice/invst_5hZDvkTQ0KXhNV1g9bLlHLXkoE',
      id: 'inv-HuOO2uDL2TF8',
      isLegacy: false,
      latestCharge: {
        __typename: TYPENAMES.PapiCharge,
        amountRefunded: null,
        failureCode: null,
        failureMessage: null,
        id: 'inv-HuOO2uDL2TF8charge',
        refunded: false,
      },
      number: '004ABBA6-0004',
      periodStart: DateTime.local().minus({ months: 8 }).toISODate(),
      status: PapiPaymentStatus.PAID,
      total: 15000,
    },
    {
      __typename: TYPENAMES.PapiInvoice,
      createdAt: DateTime.local().minus({ months: 9 }).toISO(),
      currency: 'USD',
      description: null,
      hostedInvoiceURL:
        'https://pay.stripe.com/invoice/invst_5hZDvkTQ0KXhNV1g9bLlHLXkoE',
      id: 'inv-HuOO2uDL2TF9',
      isLegacy: false,
      latestCharge: {
        __typename: TYPENAMES.PapiCharge,
        amountRefunded: null,
        failureCode: null,
        failureMessage: null,
        id: 'inv-HuOO2uDL2TF9charge',
        refunded: false,
      },
      number: '004ABBA6-0003',
      periodStart: DateTime.local().minus({ months: 9 }).toISODate(),
      status: PapiPaymentStatus.PAID,
      total: 15000,
    },
    {
      __typename: TYPENAMES.PapiInvoice,
      createdAt: DateTime.local().minus({ months: 10 }).toISO(),
      currency: 'USD',
      description: null,
      hostedInvoiceURL:
        'https://pay.stripe.com/invoice/invst_5hZDvkTQ0KXhNV1g9bLlHLXkoE',
      id: 'inv-HuOO2uDL2TF0',
      isLegacy: false,
      latestCharge: {
        __typename: TYPENAMES.PapiCharge,
        amountRefunded: null,
        failureCode: null,
        failureMessage: null,
        id: 'inv-HuOO2uDL2TF0charge',
        refunded: false,
      },
      number: '004ABBA6-0002',
      periodStart: DateTime.local().minus({ months: 10 }).toISODate(),
      status: PapiPaymentStatus.PAID,
      total: 15000,
    },
    {
      __typename: TYPENAMES.PapiInvoice,
      createdAt: DateTime.local().minus({ months: 11 }).toISO(),
      currency: 'USD',
      description: null,
      hostedInvoiceURL:
        'https://pay.stripe.com/invoice/invst_5hZDvkTQ0KXhNV1g9bLlHLXkoE',
      id: 'inv-HuOO2uDL2T1T',
      isLegacy: false,
      latestCharge: {
        __typename: TYPENAMES.PapiCharge,
        amountRefunded: 15000,
        failureCode: null,
        failureMessage: null,
        id: 'inv-HuOO2uDL2T1Tcharge',
        refunded: true,
      },
      number: '004ABBA6-0001',
      periodStart: DateTime.local().minus({ months: 11 }).toISODate(),
      status: PapiPaymentStatus.PAID,
      total: 15000,
    },
    {
      __typename: TYPENAMES.PapiInvoice,
      createdAt: DateTime.local().minus({ months: 20 }).toISO(),
      currency: 'USD',
      description: null,
      hostedInvoiceURL: 'https://api.staging.hint.com/api/files/Di1d9zlk0ada',
      id: 'inv-hzojahQqD5Vo',
      isLegacy: true,
      latestCharge: null,
      number: null,
      periodStart: DateTime.local().minus({ months: 20 }).toISODate(),
      status: PapiPaymentStatus.PAID,
      total: 50000,
    },
  ],
  joinedPracticeDate: '2017-05-01T12:00:00.000Z',
  lastName: 'Belcher',
  legacyPerson: MOCK_LEGACY_MEMBER,
  mdhqID: '5800',
  mdhqPatientURL: 'https://blah.com',
  membershipActivityStatus: PapiMembershipActivityStatus.MEMBERSHIP_ACTIVE,
  membershipPaymentStatus: PapiMembershipPaymentStatus.MEMBERSHIP_PAID,
  membershipTermsAcceptedAt: '2017-05-01T12:00:00.000Z',
  middleName: null,
  mostRecentDoctor: MOCK_PROVIDERS[0],
  cardPaymentMethods: [
    {
      __typename: TYPENAMES.PapiPaymentCardEdge,
      node: {
        __typename: TYPENAMES.PapiPaymentCard,
        deleted: false,
        expiryMonth: 4,
        expiryYear: 2023,
        id: 'defcard',
        last4Digits: '1452',
        name: 'American Express - credit',
        personID: 'lbelcher',
      },
    },
    {
      __typename: TYPENAMES.PapiPaymentCardEdge,
      node: {
        __typename: TYPENAMES.PapiPaymentCard,
        deleted: true,
        expiryMonth: 3,
        expiryYear: 2022,
        id: 'oldcard',
        last4Digits: '1010',
        name: 'Mastercard - credit',
        personID: 'lbelcher',
      },
    },
  ],
  phoneNumber: '+16093993438',
  privacyPolicyAcceptedAt: '2017-05-01T12:00:00.000Z',
  role: PapiRole.PARSLEY_MEMBER,
  selfPaymentOfServicesTermsAcceptedAt: '2017-05-01T12:00:00.000Z',
  ssn: '123456789',
  stripeCustomerID: 'cus_F2LBMaPvpBpojX',
  termsOfUseAcceptedAt: '2017-05-01T12:00:00.000Z',
};

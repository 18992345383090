import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';

import {
  MEMBER_QUERY,
  MEMBER_VISITS_QUERY,
} from 'app/components/pages/MemberVisitsPage/MemberVisitsPage';
import {
  PapiAppointmentGroup,
  PapiAppointmentStatus,
} from 'app/types/generated/globalTypes';
import {
  LabCount_Query,
  LabCount_QueryVariables,
} from 'app/types/generated/LabCount_Query';
import {
  MemberPageVisits_Query,
  MemberPageVisits_QueryVariables,
} from 'app/types/generated/MemberPageVisits_Query';
import {
  MemberVisits_Query,
  MemberVisits_QueryVariables,
} from 'app/types/generated/MemberVisits_Query';

// Types & constants ////////////////////////////////
interface Props {
  personID: string;
  planDisplayName: string;
}

/** Displays a tally of how many included visits/lab tests the member has used */
/** If the member is FFS, it displays that instead */
export const MemberVisitAndLabCount: FC<Props> = ({
  personID,
  planDisplayName,
}) => {
  const memberResult = useQuery<
    MemberPageVisits_Query,
    MemberPageVisits_QueryVariables
  >(MEMBER_QUERY, {
    variables: {
      personID: personID,
    },
  });

  const [getMemberVisits, { data: visitData, loading }] = useLazyQuery<
    MemberVisits_Query,
    MemberVisits_QueryVariables
  >(MEMBER_VISITS_QUERY);

  const member = memberResult.data?.member;
  const visits = visitData?.visits;
  const allMemberPurchases =
    member && member.allPurchases ? member.allPurchases : [];

  const [selectedPurchasePeriodID, setSelectedPurchasePeriodID] = useState<
    string | null
  >(null);

  const labCount = useQuery<LabCount_Query, LabCount_QueryVariables>(
    LAB_COUNT_QUERY,
    {
      variables: {
        filter: {
          dedupe: false,
          includeAlreadyOrdered: true,
          purchasePeriodID: selectedPurchasePeriodID,
        },
        getPersonV2Id: personID,
      },
    }
  );

  useEffect(() => {
    if (member) {
      // Select the current plan by default if there is one
      if (member.currentPurchase && member.currentPurchase.purchasePeriodId) {
        setSelectedPurchasePeriodID(member.currentPurchase.purchasePeriodId);
        // Otherwise set it to the first plan
      } else if (allMemberPurchases.length > 0) {
        setSelectedPurchasePeriodID(allMemberPurchases[0].purchasePeriodId);
      }
    }
  }, [allMemberPurchases, member]);

  useEffect(() => {
    if (selectedPurchasePeriodID && member) {
      getMemberVisits({
        variables: {
          personID: member.id,
          purchasePeriodID: selectedPurchasePeriodID,
        },
      });
    }
  }, [selectedPurchasePeriodID, member, getMemberVisits]);

  useEffect(() => {
    if (selectedPurchasePeriodID) {
      getMemberVisits({
        variables: { personID, purchasePeriodID: selectedPurchasePeriodID },
      });
    }
  }, [selectedPurchasePeriodID, getMemberVisits, personID]);

  if (!member || loading || allMemberPurchases.length === 0 || !visits) {
    return <div />;
  }

  let clinicianString = '';
  let coachString = '';
  let baselineLabString = '';

  const visitsOnPlan = visits.filter((v) => {
    return v.purchasePeriodID === selectedPurchasePeriodID;
  });

  /* This should not be hard-coded. EPD-497 */
  const isFeeForServiceClinician =
    planDisplayName === 'Medical Only' ||
    planDisplayName === 'Medical and Coaching' ||
    planDisplayName === 'Foundation' ||
    planDisplayName === 'Total Health';

  /* This should not be hard-coded. EPD-497 */
  const isFeeForServiceCoaching =
    planDisplayName === 'Foundation' || planDisplayName === 'Total Health';

  if (isFeeForServiceClinician) {
    clinicianString = 'FFS clinician visits';
  } else {
    const clinicianVisitsTotal = visitsOnPlan.filter((v) => {
      return v.details.group === PapiAppointmentGroup.MEDICAL;
    });

    const clinicianVisitsCompleted = clinicianVisitsTotal.filter((v) => {
      return (
        v.status === PapiAppointmentStatus.COMPLETED ||
        v.status === PapiAppointmentStatus.NO_SHOW ||
        v.status === PapiAppointmentStatus.CANCELLED
      );
    });

    const nClinVisitsTotal = clinicianVisitsTotal.length;
    const nClinVisitsRemaining =
      nClinVisitsTotal - clinicianVisitsCompleted.length;

    clinicianString = `${nClinVisitsRemaining} of ${nClinVisitsTotal} clinician visits remaining`;
  }

  if (isFeeForServiceCoaching) {
    coachString = 'FFS coaching visits';
  } else {
    const coachingVisitsTotal = visitsOnPlan.filter((v) => {
      return v.details.group === PapiAppointmentGroup.COACHING;
    });

    const coachingVisitsCompleted = coachingVisitsTotal.filter((v) => {
      return (
        v.status === PapiAppointmentStatus.COMPLETED ||
        v.status === PapiAppointmentStatus.NO_SHOW ||
        v.status === PapiAppointmentStatus.CANCELLED
      );
    });

    const nCoachVisitsTotal = coachingVisitsTotal.length;
    if (nCoachVisitsTotal === 0) {
      coachString = 'No Health Coach visits included with current plan';
    } else {
      const nCoachVisitsRemaining =
        nCoachVisitsTotal - coachingVisitsCompleted.length;
      coachString = `${nCoachVisitsRemaining} of ${nCoachVisitsTotal} health coach visits remaining`;
    }
  }

  const labCountTotal = labCount.data?.member.labPreorderedTests.edges.length;

  if (!labCount.data || !labCountTotal || labCountTotal === 0) {
    baselineLabString = 'No Baseline Labs included with current plan';
  } else {
    const labCountCompleted = labCount.data.member.labPreorderedTests.edges.filter(
      (v) => {
        return v.node?.labPreorderItemSource?.labOrderItemID !== null;
      }
    );
    baselineLabString = `${
      labCountTotal - labCountCompleted.length
    } of ${labCountTotal} baseline labs remaining`;
  }

  return (
    <div>
      <br />
      {clinicianString}
      <br />
      {coachString}
      <br />
      {baselineLabString}
    </div>
  );
};

export const LAB_COUNT_QUERY = gql`
  query LabCount_Query($getPersonV2Id: ID!, $filter: LabPreorderItemFilter) {
    member: getPersonV2(id: $getPersonV2Id) {
      labPreorderedTests(filter: $filter) {
        edges {
          node {
            id
            labPreorderItemSource {
              id
              labOrderItemID
            }
          }
        }
      }
    }
  }
`;
